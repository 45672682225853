import { useState, useEffect, useMemo, useCallback } from "react";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import {
  LogAccountInfoUpdateEventProps,
  LogAccountPasswordChangedEventProps,
  LogCloseEmailModalEventProps,
  LogLikeMenuItemEventProps,
  LogLoginLinkClickedEventProps,
  LogLogoutEventProps,
  LogOnlineOrderClickedEventProps,
  LogOpenEmailModalEventProps,
  LogOpenFeedbackModalEventProps,
  LogPrivacyPolicyClickedEventProps,
  LogPromotionEmailSubmitEventProps,
  LogReservationClickedEventProps,
  LogRestaurantSocialClickedEventProps,
  LogSaveMenuItemEventProps,
  LogSaveRestaurantEventProps,
  LogScreenViewProps,
  LogShareDiscoveryContentItemEventProps,
  LogShareMenuEventProps,
  LogShareMenuItemEventProps,
  LogShareRestaurantEventProps,
  LogSignUpIconClickedEventProps,
  LogSubmitEmailModalEventProps,
  LogSubmitFeedbackEventProps,
  LogTermsOfUseClickedEventProps,
  LogViewMenuEventProps,
  LogViewMenuItemEventProps,
  LogViewRecommendedDrinkEventProps,
  LogViewRestaurantEventProps,
} from "../interfaces/AnalyticsInterface";
import { AnalyticEvent, PLATFORM } from "../constants/analytics-constants";
import { useWidgetMode } from "../context/WidgetModeContext";
import { COOKIE_INITIATION_METHOD } from "../constants/cookie-constants";
import { getValue, removeValue, setValue } from "../utils/cookies";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

let isFirebaseInitialized = false;
let firebaseApp: FirebaseApp;
let firebaseAnalytics: Analytics;

export const useFirebaseAnalytics = () => {
  const [isInitialized, setInitialized] = useState<boolean>(
    isFirebaseInitialized
  );
  const [initiationMethod, setInitiationMethod] = useState<string>(
    getValue(COOKIE_INITIATION_METHOD) ?? PLATFORM.WEB_APP
  );
  const { isWidget } = useWidgetMode();
  const appPlatform = useMemo(
    () => (isWidget ? PLATFORM.WIDGET : PLATFORM.WEB_APP),
    [isWidget]
  );

  useEffect(() => {
    if (!isInitialized) {
      firebaseApp = initializeApp(firebaseConfig);
      firebaseAnalytics = getAnalytics(firebaseApp);
      isFirebaseInitialized = true;
      setInitialized(true);
    }
  }, [isInitialized]);

  const logAccountInfoUpdateEvent = useCallback(
    ({ field, status }: LogAccountInfoUpdateEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.ACCOUNT_INFO_UPDATE, {
        field: field,
        platform: appPlatform,
        status: status,
      });
    },
    [appPlatform]
  );

  const logAccountPasswordChangedEvent = useCallback(
    ({ status }: LogAccountPasswordChangedEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.ACCOUNT_PASSWORD_CHANGED, {
        platform: appPlatform,
        status: status,
      });
    },
    [appPlatform]
  );

  const logCloseEmailModalEvent = useCallback(
    ({ restaurantName, restaurantUrlID }: LogCloseEmailModalEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.CLOSE_EMAIL_MODAL, {
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logDeleteAccountClickedEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.TAP_DELETE_ACCOUNT, {
      platform: appPlatform,
    });
  }, [appPlatform]);

  const logDeleteAccountConfirmationEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.DELETE_ACCOUNT_CONFIRMATION, {
      platform: appPlatform,
    });
  }, [appPlatform]);

  const logFavoritedDishesClickedEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.TAP_FAVORITED_DISHES, {
      platform: appPlatform,
    });
  }, [appPlatform]);

  const logFavoritedRestaurantsClickedEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.TAP_FAVORITED_RESTAURANTS, {
      platform: appPlatform,
    });
  }, [appPlatform]);

  const logLikeMenuItemEvent = useCallback(
    ({
      hasMultiplePhoto,
      hasPhoto,
      hasSpecialTag,
      hasVideo,
      itemType,
      menuItemID,
      menuItemName,
      menuSectionID,
      menuSectionName,
    }: LogLikeMenuItemEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.LIKE_MENU_ITEM, {
        item_type: itemType,
        menu_item_has_multiple_photos: hasMultiplePhoto,
        menu_item_has_photo: hasPhoto,
        menu_item_has_special_tag: hasSpecialTag,
        menu_item_has_video: hasVideo,
        menu_item_id: menuItemID,
        menu_item_name: menuItemName,
        menu_section_id: menuSectionID,
        menu_section_name: menuSectionName,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logLoginEmailSuccessEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.LOGIN_EMAIL_SUCCESS, {
      initiation_method: initiationMethod,
      platform: appPlatform,
    });
  }, [appPlatform, initiationMethod]);

  const logLoginLinkClickedEvent = useCallback(
    ({ label }: LogLoginLinkClickedEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.TAP_LOGIN_LINK, {
        initiation_method: initiationMethod,
        label: label,
        platform: appPlatform,
      });
    },
    [appPlatform, initiationMethod]
  );

  const logOnlineOrderClickedEvent = useCallback(({ restaurantName, restaurantUrlID, service, view }: LogOnlineOrderClickedEventProps) => {
    logEvent(firebaseAnalytics, AnalyticEvent.TAP_ONLINE_ORDER, {
      "restaurant_name": restaurantName,
      "restaurant_url_id": restaurantUrlID,
      "service": service,
      "platform": appPlatform,
      "view": view,
    });
    },
    [appPlatform]
  );

  const logLogoutEvent = useCallback(
    ({ logoutSource }: LogLogoutEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.LOG_OUT, {
        logout_source: logoutSource,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logOpenEmailModalEvent = useCallback(
    ({ restaurantName, restaurantUrlID }: LogOpenEmailModalEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.OPEN_EMAIL_MODAL, {
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logOpenFeedbackModalEvent = useCallback(
    ({ restaurantName, restaurantUrlID }: LogOpenFeedbackModalEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.TAP_FEEDBACK_ICON, {
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logOrderButtonClickedEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.TAP_ORDER_BUTTON, {
      platform: appPlatform,
    });
  }, [appPlatform]);

  const logPrivacyPolicyClickedEvent = useCallback(
    ({ label }: LogPrivacyPolicyClickedEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.TAP_PRIVACY_POLICY, {
        initiation_method: initiationMethod,
        label: label,
        platform: appPlatform,
      });
    },
    [appPlatform, initiationMethod]
  );

  const logPromotionEmailSubmitEvent = useCallback(
    ({
      announcementType,
      hasPhoto,
      restaurantName,
      restaurantUrlID,
    }: LogPromotionEmailSubmitEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.PROMOTION_EMAIL_SUBMIT, {
        announcement_has_photo: hasPhoto,
        announcement_type: announcementType,
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logReservationClickedEvent = useCallback(({ restaurantName, restaurantUrlID, service, view }: LogReservationClickedEventProps) => {
    logEvent(firebaseAnalytics, AnalyticEvent.TAP_RESERVATIONS, {
      "restaurant_name": restaurantName,
      "restaurant_url_id": restaurantUrlID,
      "service": service,
      "platform": appPlatform,
      "view": view,
    });
  }, [appPlatform]);

  const logReserveButtonClickedEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.TAP_ORDER_BUTTON, {
      platform: appPlatform,
    });
  }, [appPlatform]);

  const logRestaurantSocialClickedEvent = useCallback(
    ({
      restaurantName,
      restaurantUrlID,
      socialMedia,
    }: LogRestaurantSocialClickedEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.TAP_RESTAURANT_SOCIAL, {
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        social_media: socialMedia,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logSaveMenuItemEvent = useCallback(
    ({
      hasMultiplePhoto,
      hasPhoto,
      hasSpecialTag,
      hasVideo,
      itemType,
      menuItemID,
      menuItemName,
      menuSectionID,
      menuSectionName,
    }: LogSaveMenuItemEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.FAVORITE_MENU_ITEM, {
        item_type: itemType,
        menu_item_has_multiple_photos: hasMultiplePhoto,
        menu_item_has_photo: hasPhoto,
        menu_item_has_special_tag: hasSpecialTag,
        menu_item_has_video: hasVideo,
        menu_item_id: menuItemID,
        menu_item_name: menuItemName,
        menu_section_id: menuSectionID,
        menu_section_name: menuSectionName,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logSaveRestaurantEvent = useCallback(
    ({
      city,
      postalCode,
      cuisine,
      name,
      restaurantUrlID,
      restaurantID,
    }: LogSaveRestaurantEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.FAVORITE_RESTAURANT, {
        city,
        zip_code: postalCode,
        cuisine_name: cuisine,
        restaurant_name: name,
        restaurant_url_id: restaurantUrlID,
        restaurant_id: restaurantID,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logScreenView = useCallback(
    ({ screenName }: LogScreenViewProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.SCREEN_VIEW, {
        firebase_screen: screenName,
        page_title: screenName,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logShareDiscoveryContentItemEvent = useCallback(
    ({
      contentID,
      title,
      restaurantName,
      restaurantUrlID,
      shareMedium,
    }: LogShareDiscoveryContentItemEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.SHARE_DISCOVERY_CONTENT_ITEM, {
        content_id: contentID,
        title: title,
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        share_medium: shareMedium,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logShareMenuEvent = useCallback(
    ({
      menuID,
      menuName,
      restaurantUrlID,
      restaurantName,
      shareMedium,
    }: LogShareMenuEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.SHARE_MENU, {
        menu_id: menuID,
        menu_name: menuName,
        restaurant_id: restaurantUrlID,
        restaurant_name: restaurantName,
        share_medium: shareMedium,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logShareMenuItemEvent = useCallback(
    ({
      hasPhoto,
      itemType,
      menuItemID,
      menuItemName,
      menuSectionID,
      menuSectionName,
      shareMedium,
    }: LogShareMenuItemEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.SHARE_MENU_ITEM, {
        item_type: itemType,
        menu_item_has_photo: hasPhoto,
        menu_item_id: menuItemID,
        menu_item_name: menuItemName,
        menu_section_id: menuSectionID,
        menu_section_name: menuSectionName,
        share_medium: shareMedium,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logShareRestaurantEvent = useCallback(
    ({
      shareMedium,
      city,
      postalCode,
      cuisine,
      restaurantName,
      restaurantUrlID,
    }: LogShareRestaurantEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.SHARE_RESTAURANT, {
        share_medium: shareMedium,
        city,
        zip_code: postalCode,
        cuisine_name: cuisine,
        restaurant_name: restaurantName,
        restaurant_id: restaurantUrlID,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logSignInAppleEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.TAP_SIGN_IN_APPLE, {
      initiation_method: initiationMethod,
      platform: appPlatform,
    });
  }, [appPlatform, initiationMethod]);

  const logSignInAppleSuccessEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.SIGN_IN_APPLE_SUCCESS, {
      initiation_method: initiationMethod,
      platform: appPlatform,
    });
  }, [appPlatform, initiationMethod]);

  const logSignInGoogleEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.TAP_SIGN_IN_GOOGLE, {
      initiation_method: initiationMethod,
      platform: appPlatform,
    });
  }, [appPlatform, initiationMethod]);

  const logSignInGoogleSuccessEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.SIGN_IN_GOOGLE_SUCCESS, {
      initiation_method: initiationMethod,
      platform: appPlatform,
    });
  }, [appPlatform, initiationMethod]);

  const logSignUpEmailEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.TAP_SIGN_UP_EMAIL, {
      initiation_method: initiationMethod,
      platform: appPlatform,
    });
  }, [appPlatform, initiationMethod]);

  const logSignUpEmailCredSuccessEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.SIGN_UP_EMAIL_CRED_SUCCESS, {
      initiation_method: initiationMethod,
      platform: appPlatform,
    });
  }, [appPlatform, initiationMethod]);

  const logSignUpEmailSuccessEvent = useCallback(() => {
    logEvent(firebaseAnalytics, AnalyticEvent.SIGN_UP_EMAIL_SUCCESS, {
      initiation_method: initiationMethod,
      platform: appPlatform,
    });
  }, [appPlatform, initiationMethod]);

  const logSignUpIconClickedEvent = useCallback(
    ({ label }: LogSignUpIconClickedEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.TAP_SIGN_UP_ICON, {
        label: label,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logSubmitEmailModalEvent = useCallback(
    ({ restaurantName, restaurantUrlID }: LogSubmitEmailModalEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.SUBMIT_EMAIL, {
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logSubmitFeedbackEvent = useCallback(
    ({ restaurantName, restaurantUrlID }: LogSubmitFeedbackEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.SUBMIT_FEEDBACK, {
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logTermOfUseClickedEvent = useCallback(
    ({ label }: LogTermsOfUseClickedEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.TAP_TERMS_OF_USE, {
        initiation_method: initiationMethod,
        label: label,
        platform: appPlatform,
      });
    },
    [appPlatform, initiationMethod]
  );

  const logUnSaveMenuItemEvent = useCallback(
    ({
      hasMultiplePhoto,
      hasPhoto,
      hasSpecialTag,
      hasVideo,
      itemType,
      menuItemID,
      menuItemName,
      menuSectionID,
      menuSectionName,
    }: LogSaveMenuItemEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.UNFAVORITE_MENU_ITEM, {
        item_type: itemType,
        menu_item_has_multiple_photos: hasMultiplePhoto,
        menu_item_has_photo: hasPhoto,
        menu_item_has_special_tag: hasSpecialTag,
        menu_item_has_video: hasVideo,
        menu_item_id: menuItemID,
        menu_item_name: menuItemName,
        menu_section_id: menuSectionID,
        menu_section_name: menuSectionName,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logUnSaveRestaurantEvent = useCallback(
    ({
      city,
      postalCode,
      cuisine,
      name,
      restaurantUrlID,
      restaurantID,
    }: LogSaveRestaurantEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.UNFAVORITE_RESTAURANT, {
        city,
        zip_code: postalCode,
        cuisine_name: cuisine,
        restaurant_name: name,
        restaurant_url_id: restaurantUrlID,
        restaurant_id: restaurantID,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logViewDiscoveryContentEvent = useCallback(
    () => {
      logEvent(firebaseAnalytics, AnalyticEvent.VIEW_DISCOVERY_CONTENT, {
        initiation_method: initiationMethod,
        platform: appPlatform,
      });
    },
    [appPlatform, initiationMethod]
  );

  const logViewMenuEvent = useCallback(
    ({
      menuName,
      menuID,
      restaurantName,
      restaurantUrlID,
    }: LogViewMenuEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.VIEW_MENU, {
        initiation_method: initiationMethod,
        menu_id: menuID,
        menu_name: menuName,
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        platform: appPlatform,
      });
    },
    [appPlatform, initiationMethod]
  );

  const logViewMenuItemEvent = useCallback(
    ({
      hasMultiplePhoto,
      hasPhoto,
      hasSpecialTag,
      hasVideo,
      itemType,
      menuID,
      menuItemID,
      menuItemName,
      menuName,
      menuSectionID,
      menuSectionName,
      restaurantName,
      restaurantUrlID,
    }: LogViewMenuItemEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.VIEW_MENU_ITEM, {
        initiation_method: initiationMethod,
        item_type: itemType,
        menu_id: menuID,
        menu_item_has_multiple_photos: hasMultiplePhoto,
        menu_item_has_photo: hasPhoto,
        menu_item_has_special_tag: hasSpecialTag,
        menu_item_has_video: hasVideo,
        menu_item_id: menuItemID,
        menu_item_name: menuItemName,
        menu_name: menuName,
        menu_section_id: menuSectionID,
        menu_section_name: menuSectionName,
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        platform: appPlatform,
      });
    },
    [appPlatform, initiationMethod]
  );

  const logViewRecommendedDrinkEvent = useCallback(
    ({
      drinkItemID,
      drinkName,
      parentMenuItemID,
      parentMenuItemName,
    }: LogViewRecommendedDrinkEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.VIEW_RECOMMENDED_DRINK, {
        drink_id: drinkItemID,
        drink_name: drinkName,
        parent_menu_item_id: parentMenuItemID,
        parent_menu_item_name: parentMenuItemName,
        platform: appPlatform,
      });
    },
    [appPlatform]
  );

  const logViewRestaurantEvent = useCallback(
    ({
      restaurantID,
      restaurantName,
      restaurantUrlID,
      cuisine,
      postalCode,
      city,
    }: LogViewRestaurantEventProps) => {
      logEvent(firebaseAnalytics, AnalyticEvent.VIEW_RESTAURANT, {
        initiation_method: initiationMethod,
        restaurant_id: restaurantID,
        restaurant_name: restaurantName,
        restaurant_url_id: restaurantUrlID,
        cuisine_name: cuisine,
        zip_code: postalCode,
        city: city,
        platform: appPlatform,
      });
    },
    [appPlatform, initiationMethod]
  );

  const trackInitiationMethod = useCallback(
    ({ platform }: { platform?: string | null }) => {
      if (platform) {
        setInitiationMethod(platform);
        setValue(COOKIE_INITIATION_METHOD, platform, 1);
      } else {
        setInitiationMethod(PLATFORM.WEB_APP);
        removeValue(COOKIE_INITIATION_METHOD);
      }
    },
    []
  );

  return {
    initiationMethod,
    logAccountInfoUpdateEvent,
    logAccountPasswordChangedEvent,
    logCloseEmailModalEvent,
    logDeleteAccountClickedEvent,
    logDeleteAccountConfirmationEvent,
    logFavoritedDishesClickedEvent,
    logFavoritedRestaurantsClickedEvent,
    logLikeMenuItemEvent,
    logLoginEmailSuccessEvent,
    logLoginLinkClickedEvent,
    logLogoutEvent,
    logOnlineOrderClickedEvent,
    logOpenEmailModalEvent,
    logOpenFeedbackModalEvent,
    logOrderButtonClickedEvent,
    logPrivacyPolicyClickedEvent,
    logPromotionEmailSubmitEvent,
    logRestaurantSocialClickedEvent,
    logReserveButtonClickedEvent,
    logReservationClickedEvent,
    logSaveMenuItemEvent,
    logSaveRestaurantEvent,
    logScreenView,
    logShareDiscoveryContentItemEvent,
    logShareMenuEvent,
    logShareMenuItemEvent,
    logShareRestaurantEvent,
    logSignInAppleEvent,
    logSignInAppleSuccessEvent,
    logSignInGoogleEvent,
    logSignInGoogleSuccessEvent,
    logSignUpEmailEvent,
    logSignUpEmailCredSuccessEvent,
    logSignUpEmailSuccessEvent,
    logSignUpIconClickedEvent,
    logSubmitEmailModalEvent,
    logSubmitFeedbackEvent,
    logTermOfUseClickedEvent,
    logUnSaveMenuItemEvent,
    logUnSaveRestaurantEvent,
    logViewDiscoveryContentEvent,
    logViewMenuEvent,
    logViewMenuItemEvent,
    logViewRecommendedDrinkEvent,
    logViewRestaurantEvent,
    trackInitiationMethod,
  };
};
