export const AnalyticEvent = {
  ACCOUNT_INFO_UPDATE: "account_info_update",
  ACCOUNT_PASSWORD_CHANGED: "account_password_changed",
  CLOSE_EMAIL_MODAL: 'close_email_modal',
  DELETE_ACCOUNT_CONFIRMATION: "delete_account_confirmation",
  FAVORITE_MENU_ITEM: 'favorite_menu_item',
  FAVORITE_RESTAURANT: 'favorite_restaurant',
  LIKE_MENU_ITEM: "like_menu_item",
  LOG_OUT: "log_out",
  LOGIN_EMAIL_SUCCESS: "login_email_success",
  OPEN_EMAIL_MODAL: 'open_email_modal',
  PROMOTION_EMAIL_SUBMIT: 'promotion_submit_email',
  SCREEN_VIEW: 'screen_view',
  SIGN_IN_APPLE_SUCCESS: 'sign_in_apple_success',
  SIGN_IN_GOOGLE_SUCCESS: 'sign_in_google_success',
  SIGN_UP_EMAIL_CRED_SUCCESS: 'sign_up_email_cred_success',
  SIGN_UP_EMAIL_SUCCESS: 'sign_up_email_success',
  SHARE_DISCOVERY_CONTENT_ITEM: 'share_discovery_content_item',
  SHARE_MENU: "share_menu",
  SHARE_MENU_ITEM: "share_menu_item",
  SHARE_RESTAURANT: "share_restaurant",
  SUBMIT_EMAIL: 'submit_email',
  SUBMIT_FEEDBACK: 'tap_submit_feedback',
  TAP_DELETE_ACCOUNT: "tap_delete_account",
  TAP_FAVORITED_DISHES: 'tap_favorited_dishes',
  TAP_FAVORITED_RESTAURANTS: 'tap_favorited_restaurants',
  TAP_FEEDBACK_ICON: 'tap_feedback_icon',
  TAP_LOGIN_LINK: "tap_login_link",
  TAP_ONLINE_ORDER: "tap_order_online",
  TAP_ORDER_BUTTON: "order",
  TAP_PRIVACY_POLICY: 'tap_privacy_policy',
  TAP_RESERVATIONS: 'tap_reservations',
  TAP_RESTAURANT_SOCIAL: 'tap_restaurant_social',
  TAP_SIGN_IN_APPLE: 'tap_sign_in_apple',
  TAP_SIGN_IN_GOOGLE: 'tap_sign_in_google',
  TAP_SIGN_UP_ICON: "tap_sign_up_icon",
  TAP_SIGN_UP_EMAIL: 'tap_sign_up_email',
  TAP_TERMS_OF_USE: 'tap_terms_of_use',
  UNFAVORITE_MENU_ITEM: 'unfavorite_menu_item',
  UNFAVORITE_RESTAURANT: 'unfavorite_restaurant',
  VIEW_DISCOVERY_CONTENT: 'view_discovery_content',
  VIEW_MENU: "view_menu",
  VIEW_MENU_ITEM: "view_menu_item",
  VIEW_RECOMMENDED_DRINK: "view_recommended_drink",
  VIEW_RESTAURANT: "view_restaurant",
};

export const PageView = {
  ACCOUNT_INFO: "account-info",
  DISCOVERY_CONTENT_PAGE: "discovery-content-page",
  CHANGE_PASSWORD: "change-password",
  FAVORITES: "favorites",
  FORGOT_PASSWORD: "forgot-password",
  LOGIN: "login",
  MENU: "menu-view",
  MENU_ITEM: "menu-item-view",
  NOT_FOUND: "not-found",
  ONBOARDING: "onboarding",
  RESET_PASSWORD: "reset-password",
  SETTINGS: "settings",
  SIGN_UP: "sign-up"
};

export const ShareMedium = {
  COPY_LINK: "copy_link",
  SMS: "sms",
  WHATSAPP: "whatsapp",
  FACEBOOK: "facebook",
  FACEBOOK_MESSENGER: "facebook_messenger",
  STANDARD: "standard",
  TWITTER: "twitter",
};

export const LOGOUT_SOURCE = {
  SETTINGS: "settings_logout",
  TOKEN_EXPIRE: "token_expiration",
};

export const PAGE_LABEL = {
  MENU_ITEM_VIEW: "menu_item_page",
  MENU_VIEW: "menu_page",
  ONBOARDING: 'onboarding_page',
  SETTINGS: 'settings_page',
  SIGN_UP: 'sign_up_page',
  PROFILE: 'profile_page',
};

export const PLATFORM = {
  APP_CLIP: 'app_clip',
  WEB_APP: 'web_app',
  WIDGET: 'widget',
};

export const EventStatus = {
  SUCCESS: "success",
  FAILURE: "failure",
};

