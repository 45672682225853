import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { PATHS } from '../constants/taptab-paths';

const useGlobalStyle = () => {
  const location = useLocation();

  useEffect(() => {
    let style: {
      backgroundBlendMode?: string;
      backdropFilter?: string;
      backgroundImage?: string;
      backgroundPosition?: string
      backgroundRepeat?: string;
      backgroundSize?: string;
      backgroundColor?: string
    } = {
      backgroundBlendMode: '',
      backdropFilter: '',
      backgroundImage: '',
      backgroundPosition: '',
      backgroundRepeat: '',
      backgroundSize: '',
      backgroundColor: '',
    };

    const paths: string[] = location?.pathname?.split('/');
    const path = paths?.[1];
    switch (path) {
    case PATHS.LANDING:
      style.backgroundImage = 'url("/assets/LandingBackground.webp")';
      style.backgroundRepeat = 'no-repeat';
      style.backgroundSize = 'cover';
      style.backgroundPosition = 'center'
      break;
    case PATHS.FORGOT_PASSWORD:
    case PATHS.LOGIN:
    case PATHS.RESET_PASSWORD:
    case PATHS.SIGN_UP:
    case PATHS.ONBOARDING:
      style.backgroundBlendMode = 'darken';
      style.backgroundColor = 'rgba(0, 0, 0, .75)';
      style.backdropFilter = 'blur(4px)';
      style.backgroundImage = 'url("/assets/AuthBackground.jpg")';
      style.backgroundRepeat = 'no-repeat';
      style.backgroundSize = 'cover';
      break;
    case PATHS.ACCOUNT_PROFILE:
    case PATHS.CHANGE_PASSWORD:
    case PATHS.FAVORITES:
    case PATHS.RESTAURANTS:
    case PATHS.SETTINGS:
      style.backgroundColor = 'white';
      break;
    default:
      style.backgroundColor = 'black';
      break;
    }

    Object.entries(style).forEach(([key, value]) => {
      if (value) {
        // set style values on html
        (document.documentElement.style as any)[key] = value;
      } else {
        (document.documentElement.style as any)[key] = '';
      }
    });

  }, [location.pathname]); // Rerun when the pathname changes
};

export default useGlobalStyle;
